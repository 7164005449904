<template>
  <el-dialog v-model="dialogFormVisible" :title="title" width="500px" @close="close">
    <el-tag style="margin-bottom: 20px" type="danger">
      * 渠道编辑不改变渠道唯一key值,只对名称和备注进行修改
    </el-tag>
    <el-form ref="formRef" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="渠道名称" prop="channelName">
        <el-input v-model.trim="form.channelName" />
      </el-form-item>
      <el-form-item label="渠道备注" prop="remarks">
        <el-input v-model.trim="form.remarks" type="textarea" />
      </el-form-item>
      <!-- <el-form-item label="appKey" prop="appKey">
        <el-select v-model="form.appKey">
          <el-option :label="item.name" :value="item.appKey" v-for="(item,index) in wcs" :key="index"></el-option>
        </el-select>
      </el-form-item> -->
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  defineAsyncComponent,
  reactive,
  toRefs,
  onMounted,
} from 'vue'
import {
  doEdit,
  doAdd,
  _importChannelAddChannel,
  _importChannelUpdateChannel,
} from '@/api/channel'
import { useStore } from 'vuex'
// 引入下拉数选择
export default defineComponent({
  name: 'UserManagementEdit',
  components: {},
  props: {
    eventId: {
      type: String,
      default: '',
    },
    meetType: {
      type: String,
      default: '',
    },
    isUser: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['fetch-data'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()
    // 自定义手机验证

    const state = reactive({
      formRef: null,
      form: {
        appKey: 'WX_MINI_WEMEET',
      },
      departmentData: [],
      rules: {
        channelName: [
          { required: true, trigger: 'blur', message: '请输入渠道名称' },
        ],
        remarks: [
          { required: true, trigger: 'blur', message: '请输入渠道备注' },
        ],
        appKey: [
          { required: true, trigger: 'change', message: '请选择appKey' },
        ],
      },
      title: '',
      dialogFormVisible: false,
      roleList: [],
      wcs: [],
    })

    const showEdit = async (row) => {
      console.log(row)
      const { wcs } = JSON.parse(localStorage.getItem('customInfo'))
      state.wcs = wcs
      if (!row) {
        state.title = '添加'
      } else {
        state.title = '编辑'
        state.form = Object.assign({}, row)
      }
      state.dialogFormVisible = true
    }
    const close = () => {
      state['formRef'].resetFields()
      state.form = {
        appKey: 'WX_MINI_WEMEET',
      }
      state.dialogFormVisible = false
    }
    const save = () => {
      state['formRef'].validate(async (valid) => {
        if (valid) {
          state.form.meetingCode = props.eventId
          state.form.type = props.meetType
          console.log(state.form)

          if (props.isUser) {
            if (state.title == '添加') {
              await _importChannelAddChannel(state.form)
            } else {
              await _importChannelUpdateChannel(state.form)
            }
          } else {
            if (state.title == '添加') {
              await doAdd(state.form)
            } else {
              await doEdit(state.form)
            }
          }

          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          emit('fetch-data')
          close()
        }
      })
    }

    return {
      ...toRefs(state),
      showEdit,
      close,
      save,
    }
  },
})
</script>
